import React from 'react';
import { Link } from 'gatsby';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SocialMedia from 'components/socialMedia/SocialMedia';

import ROUTES from 'utils/routes'

import "./FooterNew.scss";

//TODO link pages in the footer
export default function FooterNew() {
  return (
    <div className="footer py-5">
      <Container>
        <Row className="py-5">
          <Col md={3}>
              <p className="footer-label">Product</p>
              <p><Link to={ROUTES.DATASETS}>Curated Data Sets</Link></p>
              <p><Link to={ROUTES.API}>API</Link></p>
              <p>Policies</p>
          </Col>
          <Col md={3}>
              <p className="footer-label">About</p>
              <p><Link to={ROUTES.ABOUT}>LobbyView</Link></p>
              <p><Link to={ROUTES.ABOUT}>Team</Link></p>
          </Col>
          <Col md={3}>
              <p className="footer-label">Help</p>
              <p>FAQs</p>
              <p>Contact Us</p>
          </Col>
          <Col md={3}>
              <p className="footer-label">Get Connected</p> 
              <SocialMedia />
          </Col>
        </Row>
      </Container>

    </div>
  )
}
