import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image'
import FooterNew from '../../footer/FooterNew';

import ROUTES from "utils/routes";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';



import './DatasetsPageNew.scss';

const IMG_STYLE = {
  "border-radius": "4px",
  "padding": "5px",
  "width": "150px", 
}

const DatasetsPage = () => {
  return (
    <div id="datasets">
      <header>
        <div>
          <h2>Curated Datasets</h2>
          <br/>
          <p id="datasets-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus eu eget tempus lacus platea suscipit in in. Etiam adipiscing gravida sed ullamcorper libero vestibulum nunc rutrum condimentum. </p>
        </div>
      </header>

      <Container>
        <center>
          <h3>Explore Our Curated Data Sets</h3>
          <br/>
        </center>

        <Row>
          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on report level data."
            route="/report_level"
            title="Report Level Data"
          />

          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on client level data."
            route="/client_level"
            title="Client Level Data"
          />

          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on bill level data."
            route="/bill_level"
            title="Bill Level Data"
          />

          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on issue level data."
            route="/issue_level"
            title="Issue Level Data"
          />

          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on network data."
            route="/network_data"
            title="Network Data"
          />

          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on text data."
            route="/text_data"
            title="Text Data"
          />
        </Row>

        <br/><br/><br/>

        <hr/>

        <br/><br/><br/>

        <div id="error-form-container">
          <div id="error-form-img-container">
          <StaticImage src="../../../images/refactor/LobbyView_Question.png" alt="investigator"/>
          </div>
          <div>
            <h3>Submit An Error Form</h3>

            <p>We are always trying to improve our processes. If you have found an error within our report-level data, please submit an error form to help us better serve you in the future.</p>

            <a href="#TODO"><Button>Full out error form</Button></a>
          </div>
        </div>

        <br/><br/><br/>
      </Container>

      

      <FooterNew />
    </div>
  )
}

export default DatasetsPage



type ClickableDatasetCardProps = {
  description: string,
  route: string,
  title: string,
}

const ClickableDatasetCard = (props:ClickableDatasetCardProps) => {
  return (
    <Col sm={12} md={6} lg={4}>
      <div className="card-container">
        <Link to={ROUTES.DATASETS+props.route} className="link">
          <div className="box card">
            <h3>{props.title}</h3>
            <br/>
            <p>{props.description}</p>
            <br/>
            <p className="learn-more-text">Learn more</p>
          </div>
        </Link>
      </div>
    </Col>
  )
}

ClickableDatasetCard.propTypes = {
  description: PropTypes.string.isRequired,
  route: PropTypes.string,
  title: PropTypes.string.isRequired,
}
